<template>
  <Card class="regulation-form">
    <Form ref="dataForm" style="max-width:800px" :model="formItem" :rules="ruleValidate" :label-width="180">
      <Form-item label='区域' prop="Region">
        <city-select-list :city="formItem.Region" @selectCity="selectCity"></city-select-list>
      </Form-item>
      <Form-item label="文件标题" prop="Title">
        <Input v-model="formItem.Title" placeholder="请输入文件标题"></Input>
      </Form-item>
      <Form-item label="发布时间" prop="PublishTime">
        <DatePicker v-model="formItem.PublishTime" type="date" placeholder="请选择" style="width: 200px"></DatePicker>
      </Form-item>
      <Form-item label="文件内容" prop="Content">
        <Input type="textarea" v-model="formItem.Content" :autosize="{minRows: 2,maxRows: 8}" placeholder="请输入内容"></Input>
      </Form-item>
      <FormItem>
        <Button type="primary" size="large" long :loading="submitting" @click="submitFrom">{{ editMode?'提交修改':'提交新增' }}</Button>
      </FormItem>
    </Form>
  </Card>
</template>

<script>
import citySelectList from '../public/CitySelectList'
import leftPad from 'leftpad'
export default {
  name: 'regulation-form',
  components: {
    citySelectList,
  },
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  watch: {
    data: function (newVal) {
      if (newVal) {
        Object.assign(this.formItem, newVal)
        this.editMode = true
      } else {
        this.editMode = false
        this.formItem = {
          Title: null,
          PublishTime: null,
          Content: null,
          Region: null,
          Province: null,
          Municipality: null,
          County: null
        }
      }
    },
  },
  data () {
    return {
      visible: false,
      editMode: false,
      formItem: {
        Tid: null,
        Title: null,
        PublishTime: null,
        Content: null,
        Region: null,
        Province: null,
        Municipality: null,
        County: null
      },
      ruleValidate: {
        Title: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        PublishTime: [
          { required: true, type: 'date', message: '不能为空', trigger: 'blur' }
        ],
        Content: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        Region: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
      },
      submitEnabled: true,
      submitting: false
    }
  },
  mounted () {
    let curItem = localStorage.getItem('regulation-current')
    if (curItem) {
      curItem = JSON.parse(curItem)
    }
    if (curItem && curItem.Tid && curItem.Tid.toString() === this.$route.params.tid) {
      this.formItem = curItem
      this.editMode = true
      if (curItem.Province > 0) {
        this.getCityCode(curItem.Province, curItem.Municipality, curItem.County)
      }
    }
  },
  methods: {
    selectCity (value) {
      this.formItem.Region = value
      if (value.length > 0) {
        this.formItem.Province = parseInt(value.substr(0, 2))
        this.formItem.Municipality = parseInt(value.substr(2, 2))
        this.formItem.County = parseInt(value.substr(4, 2))
      } else {
        this.formItem.Province = 0
        this.formItem.Municipality = 0
        this.formItem.County = 0
      }
    },
    getCityCode (province, municipality, county) {
      this.formItem.Region = leftPad(province, 2) +
        leftPad(municipality, 2) +
        leftPad(county, 2)
    },
    submitFrom () {
      if (this.editMode) {
        return this.edit()
      } else {
        return this.create()
      }
    },
    create () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          this.$Api.Project.addRegulation(this.formItem).then(() => {
            this.$Message.success('操作成功！!')
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
            this.formItem = {
              Title: null,
              PublishTime: null,
              Content: null,
              Region: null,
              Province: null,
              Municipality: null,
              County: null
            }
          }).catch(err => {
            this.$Spin.hide()
            this.$Message.error('提交失败！!' + err.message)
            this.submitEnabled = true
            this.submitting = false
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    },
    edit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          this.$Api.Project.editRegulation(this.formItem).then(() => {
            this.$Message.success('操作成功！!')
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
          }).catch(err => {
            this.$Spin.hide()
            this.$Message.error('提交失败！!' + err.message)
            this.submitEnabled = true
            this.submitting = false
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
